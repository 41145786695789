import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { RESPONSIVE_STYLES } from "../../AppConst"

/**
 * Material
 */
import { ContactFormButton } from "./common/ContactFormButton"

/**
 * Element & Styles
 */
const Node = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 40px);
  text-align: center;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
  }
`

const Wrapper = styled.div`
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-bottom: 0;
  }
`

const Icon = styled.img`
  height: 93px;
`

const Title = styled.h3`
  margin: 48px 0 0;
  padding: 0;

  font-size: 2.0rem;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  letter-spacing: 2.0px;

  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin: 24px 0 0;
    font-size: 1.6rem;
    letter-spacing: 1.6px;
  }
`

const Text = styled.p`
  display: block;
  width: 100%;
  min-height: 160px;
  margin: 24px 0 0;
  color: #222222;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 1.4px;
  line-height: 2.6rem;
  text-align: left;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    min-height: unset;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  margin: 48px auto auto;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
    margin: 24px auto auto;
  }
`

/**
 * Props
 */
type Options = {
  icon: any
  title: string
  text?: string
  url: Array<string>
  buttonText: Array<string>
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

type Props = Partial<Options>

/**
 * Component
 */
export const ContactIndexItem: React.FC<Props> = props => {
  return (
    <Node>
      <Icon src={props.icon} alt={props.title} />
      <Title>{props.title}</Title>
      {
        props.text &&
          <Wrapper><Text>{props.text}</Text></Wrapper>
      }
      <ButtonWrapper>
      {
        props.url.map((u, i) => (
          <ContactFormButton
            text={props.buttonText[i]}
            onClick={(e) => navigate(u)}
          />
        ))
      }
      </ButtonWrapper>
    </Node>
  )
}

ContactIndexItem.defaultProps = {
}
