import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../AppConst"

/**
 * Element & Styles
 */
const Node = styled.div`
  width: 100%;
  margin: 96px 0 0;
  text-align: center;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin: 48px 0 0;
  }
`

const Text = styled.p`
  margin: 0;
  color: #222222;

  font-style: normal;
  font-weight: 700;
  font-size: 2.0rem;
  line-height: 32px;
  text-align: center;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.4rem;
  }
`

const Br = styled.br`
  display: inline-block;
`

/**
 * Component
 */
export const ContactTitle: React.VFC = () => {
  return (
    <Node>
      <Text>お問い合わせ内容の確認後、担当者よりご連絡させていただきます。<Br />下記よりお問い合わせになりたい項目をご選択ください。</Text>
    </Node>
  )
}
