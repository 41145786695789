import React from "react"
import styled from "styled-components"
import Layout from "../../template/Layout"
import Head from "../../head"

/**
 * Material
 */
import {
  RESPONSIVE_STYLES,
  TABLET_STYLES,
  WRAPPER_STYLES,
} from "../../AppConst"
import { ContactTitle } from "./ContactTitle"
import { ContactIndexItem } from "./ContactIndexItem"
import { Props as BreadcrumbsData } from "../common/Breadcrumbs"
import { SectionTitle } from "../common/SectionTitle"

import iconService from "../../images/contact_service.svg"
import iconRecruit from "../../images/contact_recruit.svg"
import iconOther from "../../images/contact_other.svg"

/**
 * Element & Styles
 */
const Wrapper = styled.div`
  ${WRAPPER_STYLES}
  ${TABLET_STYLES}
  max-width: 960px;
  padding: 18px 0 160px;
  box-sizing: border-box;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 0 20px 80px;
  }
`

const CategoryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 96px 80px;
  margin: 96px 0 0;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    max-width: 335px;
    margin: 48px auto 0;
    gap: 80px 0;
  }
`

/**
 * Props
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
}

/**
 * Component
 */
export const Contact: React.VFC<Props> = ({ breadcrumbsData }) => {
  return (
    <Layout sidebar={false} breadcrumbsData={breadcrumbsData}>
      <Head
        title="お問い合わせ | ネクストライブ株式会社"
        description="ネクストライブ株式会社のお問い合わせ窓口の一覧ページです。"
      />
      <Wrapper>
        <SectionTitle title="CONTACT" subTitle="お問い合わせ" />
        <ContactTitle />
        <CategoryWrapper>
          <ContactIndexItem
            icon={iconService}
            title={`弊社の提供するサービスに関すること`}
            text={``}
            buttonText={[`サービスに関するお問い合わせ`]}
            url={[`/contact/service`]}
          />
          <ContactIndexItem
            icon={iconRecruit}
            title={`採用に関すること`}
            text={``}
            buttonText={[
              `採用エントリー`,
              `カジュアル面談`,
            ]}
            url={[
              `/contact/recruit`,
              `/contact/casualvisit`,
            ]}
          />
          <ContactIndexItem
            icon={iconOther}
            title={`その他のお問い合わせ`}
            text={``}
            buttonText={[`その他のお問い合わせ`]}
            url={[`/contact/etc`]}
          />
        </CategoryWrapper>
      </Wrapper>
    </Layout>
  )
}
