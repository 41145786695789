import React from "react"
import { Contact } from "../../components/contact"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `お問い合わせ`, path: `contact` },
]

export default function Home() {
  return (
    <Contact breadcrumbsData={breadcrumbsData} />
  )
}
